import { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ModuleSection, ModuleType } from '../../models/Module';
import StaticBreadCrumb from '../shared/breadcumb/StaticBreadCrumb';
import Button, { ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { v4 as uuid } from 'uuid';
import { ModuleBuilderEditorProps } from './ModuleBuilderTypes';
import { ContextMenuItem } from '../shared/ContextMenu';
import DataRow from '../shared/data-grid/DataRow';
import TopNavPortal from '../layout/top-menu/TopNavPortal';
import { useRecoilValue } from 'recoil';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import LanguageUtils from '../../utils/LanguageUtils';
import { Translations } from '../../models/Translation';
import TranslatableInput from '../shared/form-control/TranslatableInput';

const SectionsEditor: FC<ModuleBuilderEditorProps> = (props) => {
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const { module, patchModule, changesPending, saveTemplate } = props;

  const [editingSections, setEditingSections] = useState<Record<string, ModuleSection>>({});
  const [showNewModal, setShowNewModal] = useState(false);
  const [newSectionTranslations, setNewSectionTranslations] = useState<Translations>({});

  const { t } = useTranslation(['module-builder', 'organisation']);
  const location = useLocation();

  const breadCrumbs = useMemo(() => {
    return [
      {
        name: t('organisation:tabs.modules'),
        path: currentClient ? `/clients/${currentClient.id}/organisation#modules` : `/builder/${currentTenantId}/#module-builder`,
      },
      {
        name: LanguageUtils.getTranslation('name', module.translations),
        path: location.pathname,
      },
    ];
  }, [currentClient, currentTenantId, location.pathname, module.translations, t]);

  const onDragEnd = (result: DragEndEvent) => {
    const { active, over } = result;

    // Dropped outside of list
    if (!over) {
      return;
    }

    const activeIndex = module.sections.findIndex((x) => x.id === active.id);
    const overIndex = module.sections.findIndex((x) => x.id === over.id);
    const newSectionsOrder = arrayMove(module.sections, activeIndex, overIndex);
    patchModule({
      sections: newSectionsOrder,
    });
  };

  const onNewModalClose = () => {
    setShowNewModal(false);
    setNewSectionTranslations({});
  };

  const createNewSection = () => {
    const newSection: ModuleSection = {
      id: uuid(),
      translations: newSectionTranslations,
      forms: [],
    };
    patchModule({
      sections: [...module.sections, newSection],
    });
    onNewModalClose();
  };

  const contextItemsFor = (section: ModuleSection) => {
    const result: ContextMenuItem[] = [];

    if (!editingSections[section.id]) {
      result.push({
        title: t('module-builder:sections.menu.edit'),
        onClick: () => setEditingSections((prev) => ({ ...prev, [section.id]: section })),
      });
    }

    result.push({
      title: t('module-builder:sections.menu.remove'),
      onClick: () => removeSection(section.id),
      hide: module.type === ModuleType.Document,
    });

    return result;
  };

  const saveEditing = (sectionId: string) => {
    patchModule({ sections: module.sections.map((moduleSection) => (moduleSection.id === sectionId ? editingSections[sectionId] : moduleSection)) });
    stopEditing(sectionId);
  };

  const stopEditing = (sectionId: string) => {
    setEditingSections((prev) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [sectionId]: _, ...rest } = prev;
      return rest;
    });
  };

  const editSectionTranslations = (sectionId: string, value: Translations) => {
    setEditingSections((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        translations: value,
      },
    }));
  };

  const removeSection = (sectionId: string) => {
    patchModule({
      sections: module.sections.filter((x) => x.id !== sectionId),
    });
  };

  return (
    <div className="bg-background-1 flex h-full flex-grow flex-col" data-cy="module-builder-sections-editor">
      <TopNavPortal>
        <StaticBreadCrumb breadCrumbs={breadCrumbs} currentStepName={t('module-builder:sections.heading')} />
      </TopNavPortal>
      <div className="flex flex-shrink-0 flex-grow flex-col overflow-y-auto">
        <div className="flex items-center justify-between p-6">
          <Heading size={HeadingSize.H3}>{t('module-builder:sections.heading')}</Heading>
          <div className="flex justify-between gap-4">
            <Button data-cy="add-section" type={ButtonType.PRIMARY} onClick={() => setShowNewModal(true)}>
              {t('module-builder:add')}
            </Button>
            <Button data-cy="save-module" disabled={!changesPending} onClick={saveTemplate}>
              {t('module-builder:save')}
            </Button>
          </div>
        </div>
        <div className="flex-grow p-6 pt-0">
          <div className="flex items-end py-2">
            <div className="w-16">{/* SPACER */}</div>
            <div className="flex w-1/3 items-end">
              <div className="w-8 flex-shrink-0">#</div>
              <div className="flex-grow">{t('module-builder:sections.columns.name')}</div>
            </div>
            <div className="flex w-2/3 items-end">
              <div className="flex-grow">{t('module-builder:sections.columns.description')}</div>
            </div>
          </div>
          <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={module.sections.map((x) => x.id)} strategy={verticalListSortingStrategy}>
              <div data-cy="draggable-area">
                {module.sections.map((section, i) => (
                  <DataRow
                    key={section.id}
                    data-cy={`section-${i}`}
                    url={
                      !editingSections[section.id]
                        ? currentClient
                          ? `/clients/${currentClient.id}/module-builder/${module.id}/forms/${section.id}`
                          : `/builder/${currentTenantId}/module-builder/${module.id}/forms/${section.id}`
                        : undefined
                    }
                    contextMenuItems={contextItemsFor(section)}
                    isSortingDisabled={false}
                    sortableId={section.id}
                  >
                    <div className="flex w-1/3 items-center">
                      <div className="w-8 flex-shrink-0" data-cy="section-number">
                        {i + 1}
                      </div>
                      <div className="flex-grow text-black">
                        {editingSections[section.id] ? (
                          <TranslatableInput
                            translationKey="name"
                            translations={editingSections[section.id]?.translations}
                            onTranslationsChange={(value) => editSectionTranslations(section.id, value)}
                            data-cy="section-title-input"
                            placeholder={
                              LanguageUtils.getTranslation('name', editingSections[section.id]?.translations) ||
                              t('module-builder:sections.fields.name')
                            }
                          />
                        ) : (
                          <span className="font-medium" data-cy="section-title">
                            {LanguageUtils.getTranslation('name', section.translations)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="ml-4 flex w-2/3 items-center">
                      <div className="flex-grow">
                        {editingSections[section.id] ? (
                          <TranslatableInput
                            inputType="multiline"
                            translationKey="description"
                            translations={editingSections[section.id]?.translations}
                            onTranslationsChange={(value) => editSectionTranslations(section.id, value)}
                            data-cy="section-description-input"
                            className="w-full"
                            dynamicHeight
                            placeholder={
                              LanguageUtils.getTranslation('description', editingSections[section.id]?.translations) ||
                              t('module-builder:sections.fields.description')
                            }
                          />
                        ) : (
                          <span data-cy="section-description">{LanguageUtils.getTranslation('description', section.translations)}</span>
                        )}
                      </div>
                      {editingSections[section.id] && (
                        <div className="flex items-center">
                          <div className="mx-4 flex gap-4">
                            <Button
                              data-cy="do-save"
                              onClick={() => {
                                saveEditing(section.id);
                              }}
                              type={ButtonType.PRIMARY}
                            >
                              {t('module-builder:sections.buttons.save')}
                            </Button>
                            <Button
                              data-cy="do-cancel"
                              onClick={() => {
                                stopEditing(section.id);
                              }}
                              type={ButtonType.SECONDARY}
                            >
                              {t('module-builder:sections.buttons.cancel')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </DataRow>
                ))}
              </div>
            </SortableContext>
          </DndContext>
        </div>
      </div>
      <ModalContext.Provider value={{ open: showNewModal, onClose: onNewModalClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('module-builder:sections.modals.create.title')}
          cancelButtonTitle={t('module-builder:sections.modals.create.buttons.cancel')}
          confirmButtonTitle={t('module-builder:sections.modals.create.buttons.create')}
          onCancelClick={onNewModalClose}
          onConfirmClick={createNewSection}
          confirmDisabled={!LanguageUtils.getTranslation('name', newSectionTranslations)}
        >
          <TranslatableInput
            translationKey="name"
            translations={newSectionTranslations}
            onTranslationsChange={setNewSectionTranslations}
            data-cy="new-section-title"
            placeholder={t('module-builder:sections.fields.name')}
            label={t('module-builder:sections.fields.name')}
          />
          <TranslatableInput
            inputType="multiline"
            translationKey="description"
            translations={newSectionTranslations}
            onTranslationsChange={setNewSectionTranslations}
            data-cy="new-section-description"
            className="w-full"
            placeholder={t('module-builder:sections.fields.description')}
            label={t('module-builder:sections.fields.description')}
          />
        </StandardModal>
      </ModalContext.Provider>
    </div>
  );
};

export default SectionsEditor;
